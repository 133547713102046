<template>
  <router-link :to="{ name: 'meus-dados' }">
    <img
      id="estrategia-logo"
      :src="logoUrl"
      :style="{ filter: fillColor }"
      alt="Estratégia"
    >
  </router-link>
</template>

<script>
/*
map das cores em Filter
https://stackoverflow.com/a/53336754/6188402
https://codepen.io/sosuke/pen/Pjoqqp
*/
const logoFillColors = {
  white: 'invert(100%) sepia(1%) saturate(2306%) hue-rotate(304deg) brightness(120%) contrast(100%)',
  blue: 'invert(58%) sepia(12%) saturate(5399%) hue-rotate(189deg) brightness(104%) contrast(102%)',
};

export default {
  name: 'EstrategiaLogo',
  props: {
    color: {
      default: 'white',
      type: String,
      validator: (value) => value in logoFillColors,
    },
  },
  data() {
    return {
      logoUrl: this.$image.get('estrategia-logo'),
    };
  },
  computed: {
    fillColor() {
      return logoFillColors[this.color];
    },
  },
};
</script>

<style lang="scss">
#estrategia-logo {
  width: 144px;
  height: 32px;
}

@include c-mq(sm) {
  #estrategia-logo {
    width: 152px;
    height: 48px;
  }
}
</style>
